<template>
  <div>
    <!-- 导航 -->
    <div class="nav-title">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <span :class="{ text: refundType === 0 }" @click="setOrderType(0)">
        全部
      </span>
      <span :class="{ text: refundType === 1 }" @click="setOrderType(1)">
        退款订单
      </span>
      <span :class="{ text: refundType === 2 }" @click="setOrderType(2)">
        退货订单
      </span>
      <van-popover
        v-model="showPopover"
        :actions="actions"
        placement="bottom-end"
        theme="dark"
        @select="onSelect"
      >
        <template #reference>
          <van-icon
            name="ellipsis"
            @touchend="ellipsis"
            size="20"
            class="icon-font"
          />
        </template>
      </van-popover>
    </div>
    <!-- 订单列表 -->
    <div class="layout-content">
      <OrderCard :refundType="refundType"></OrderCard>
    </div>
  </div>
</template>

<script>
import OrderCard from "@/pages/order/refund-procedure/order-return";
export default {
  name: "after-sale",
  components: {
    OrderCard,
  },
  data() {
    return {
      showPopover: false,
      actions: [
        { text: "返回首页", icon: "wap-home-o" },
        { text: "购物车", icon: "cart-o" },
        { text: "分类", icon: "cluster-o" },
        { text: "我的", icon: "friends-o" },
      ],
      refundType: 0,
    };
  },
  methods: {
    setOrderType(num) {
      this.refundType = num;
    },
    ellipsis() {
      //  操作选项框
      this.showPopover = !this.showPopover;
    },
    onSelect(action, index) {
      if (index === 0) {
        this.$router.push("/");
      } else if (index === 1) {
        this.$router.push("/shop-cart");
      } else if (index === 2) {
        this.$router.push("/classify");
      } else if (index === 3) {
        this.$router.push("/my");
      }
    },
  },
};
</script>

<style lang="less" scoped>
// 导航栏样式
.nav-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: #fff;
  border-bottom: 1px solid #ededed;
  padding: 12px;
  font-size: 20px;
  color: #6f6f6f;

  .text {
    font-weight: 500;
    color: #000;
  }
}
.layout-content {
  padding: 52px 12px 12px 12px;
}
</style>
