<template>
  <div>
    <!-- 空白页 -->
    <!-- 这里的v-if判断需要连同 all-order.vue - back-color类名一起判断，用于控制空白页的背景颜色 -->
    <div class="blank-page-box" v-if="list.length <= 0">
      <div class="blank-page">
        <img
          src="~@/assets/common/no_evaluation_icon_is_available.png"
          class="blank-img"
        />
      </div>
    </div>
    <!-- 内容 -->
    <div class="goods-content" v-else>
      <div class="content-card" v-for="(order, o_index) in list" :key="o_index">
        <!-- 店铺名称 -->
        <div class="shop-title">
          <div class="shop-title-left" @click="goToUrl(2, order)">
            <van-icon name="shop-o" />
            <span class="title-text">供应商：{{ order.shop_name }}</span>
            <van-icon name="arrow" />
          </div>
          <span class="is-pay">{{ order.status_str }}</span>
        </div>
        <!-- 商品信息 -->
        <div class="goods-info" @click="goToUrl(1, order)">
          <div class="goods-images-box">
            <!-- 样式二 -->
            <div class="buy-details">
              <div class="goods-img-box">
                <img :src="order.goods_img" class="goods-img" />
              </div>
              <div class="sku-info">
                <div>{{ order.goods_name }}</div>
                <span class="specifications">{{ order.item_key }}</span>
              </div>
            </div>
          </div>
          <div class="price-quantity">
            <div class="price">
              <span class="price-after">￥</span>
              <span>{{ parseInt(order.total_money) || 0 }}</span>
              <span class="price-after">{{ floorNum(order.total_money) }}</span>
            </div>
            <div class="quantity">共{{ order.goods_num }}件</div>
          </div>
        </div>
        <!-- 操作按钮 -->
        <div class="operation-button">
          <div v-if="order.status === 3">
            <van-button class="buttons" round @click="delOrder(order)">
              删除订单
            </van-button>
          </div>
          <div v-if="order.status === 3">
            <van-button
              class="buttons"
              plain
              round
              type="danger"
              @click="addShoppingCart(order)"
            >
              加入购物车
            </van-button>
          </div>
        </div>
      </div>
    </div>
    <PromptPopup
      :msg="showMsg"
      :showPopup="showDialog"
      @popupFn="popupFn"
    ></PromptPopup>
  </div>
</template>

<script>
import { addShopping } from "@/api/shopping.js";
import { returnList, setDeleteReturn } from "@/api/order";
import { Toast } from "vant";
import PromptPopup from "@/components/prompt-popup/prompt-popup";
import tools from "@/common/js/tools";
export default {
  name: "order-card",
  components: {
    PromptPopup,
  },
  props: ["refundType"],
  data() {
    return {
      showDialog: false,
      msgText: "",
      showAddress: false,
      showPopup: false,
      showPay: false,
      list: [],
      page: 1,
      total: 100,
      orderIndex: -1,
      orderId: -1,
      showMsg: "",
      showType: 1,
      shoppingData: {},
    };
  },
  mounted() {
    let num = 11.222;
    this.startOrderList();
  },
  watch: {
    "_props.refundType": function () {
      this.startOrderList();
    },
  },
  methods: {
    setOrderPay(orderIndex, orderId) {
      this.orderIndex = orderIndex;
      this.orderId = orderId;
      this.showPay = true;
    },
    addShoppingCart(info) {
      this.shoppingData.number = info.goods_num;
      this.shoppingData.type = 1;
      this.shoppingData.spec_id = info.spec_id;
      this.addShopping();
    },
    delOrder(order) {
      this.showMsg = "您确定删除此订单？";
      this.orderId = order.id;
      this.showDialog = true;
    },
    popupFn(bool) {
      if (bool) {
        this.setDeleteReturn();
        this.showDialog = false;
      } else {
        this.showDialog = false;
      }
    },
    floorNum(num) {
      // 获取价格小数部分
      return tools.twoFloating(num);
      // num = this.twoNum(num) + "";
      // const result = num.toString().split(".")[1];
      // return result;
    },
    twoNum(num) {
      // 获取两位小数
      return (num * 1).toFixed(2);
    },
    async addShopping() {
      // 加入购物车
      const res = await addShopping(this.shoppingData);
      if (res.code * 1 === 1) {
        Toast.success("加入成功");
      } else {
        Toast.fail("请稍后再试");
      }
    },
    async setDeleteReturn() {
      // 删除订单
      const res = await setDeleteReturn({ id: this.orderId });
      if (res.code * 1 === 1) {
        Toast.success("删除成功");
        this.startOrderList();
      } else {
        Toast.fail(res.msg);
      }
    },
    startOrderList() {
      //初始化订单列表
      this.list = [];
      this.page = 1;
      this.total = 100;
      this.getOrderList();
    },
    async getOrderList() {
      //获取订单列表数据
      const ret = await returnList({
        refund_type: this.refundType,
      });
      if (ret.code * 1 == 1) {
        ++this.page;
        this.list = this.list.concat(ret.data.items);
        this.total = ret.data.total;
      }
    },
    goToUrl(type, data) {
      //页面跳转
      switch (type) {
        case 1:
          //订单详情
          this.$router.push({
            name: "sales-return",
            query: { orderId: data.id, shopId: data.shop_id },
          });
          break;
        case 2:
          //店铺详情
          this.$router.push({
            name: "shop-home",
            query: { shopId: data.shop_id },
          });
          break;
        case 3:
          // this.$router.push({name: 'my-collection'});
          break;
        case 4:
          break;
        default:
      }
    },
  },
};
</script>

<style lang="less" scoped>
// 空白页
.blank-page-box {
  width: 100%;
  background-color: #fff;

  .blank-page {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 194px;

    .blank-img {
      display: block;
      width: 100%;
    }
  }
}

// 订单卡片样式
.goods-content {
  margin-top: 12px;
}

.content-card {
  border-radius: 16px;
  padding: 12px;
  background-color: #fff;
  margin-top: 12px;

  .shop-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .shop-title-left {
      display: flex;
      align-items: center;
      font-size: 17px;
      color: #c6c6c6;

      .title-text {
        margin: 0 4px;
        font-weight: 500;
        color: #000;
      }
    }

    .is-pay {
      font-size: 17px;
      color: #ed301d;
    }
  }
}

// 商品信息
.goods-info {
  display: flex;
  padding: 10px 0;
  justify-content: space-between;
  border-bottom: 1px solid #ededed;

  .goods-images-box {
    overflow-x: scroll;
  }

  // 样式一
  .goods-images {
    display: flex;
  }

  .goods-img-box {
    width: 90px;
    height: 90px;
    padding: 0 5px;
    flex-shrink: 0;
    .goods-img {
      display: block;
      width: 90px;
      height: 90px;
      border-radius: 6px;
    }
  }

  // 样式二
  .buy-details {
    display: flex;
    justify-content: center;

    .sku-info {
      margin-left: 12px;
      font-size: 17px;
      padding: 7px 0 11px 0;
      width: 100%;
      div {
        margin-bottom: 20px;
      }
      .specifications {
        max-width: 70%;
        font-size: 14px;
        background: #f5f5f5;
        border-radius: 6px;
        color: #6f6f6f;
        padding: 4px 17px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        word-break: break-all;
      }
    }
  }

  .price-quantity {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .price {
      font-size: 18px;
      .price-after {
        font-size: 14px;
      }
    }

    .quantity {
      margin-top: 12px;
      font-size: 17px;
      color: #aaaaaa;
    }
  }
}

// 操作按钮
.van-button {
  width: 90px;
  height: 40px;
}

.operation-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;

  .button-box {
    margin-left: 12px;
  }
}

// 弹出层
.van-popup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px 14px;
  width: 320px;
  height: 190px;
  border-radius: 16px;
  box-sizing: border-box;

  .is-button {
    display: flex;
    justify-content: space-around;
    width: 100%;

    .button {
      width: 116px;
    }
  }
}
.buttons {
  width: 93px;
  height: 39px;
  padding: 0;
  margin-left: 12px;
}
</style>
